<template>
    <v-sheet>
        <v-row class="mt-5 align-baseline">
            <v-col class="title ml-3">관리자 변경 이력</v-col>
            <v-col class="text-right">
            </v-col>
        </v-row>
        <v-card flat class="rounded-xl">
            <v-card-text>
                <v-simple-table class="border-table">
                    <template v-slot:default>
                    <thead>
                        <tr style="background-color:#F4F6F8; height:60px; color:#333333; font-weight:600;">
                            <th class="text-center text-h6">변경일시</th>
                            <th class="text-center text-h6">작업자</th>
                            <th class="text-center text-h6">대상자</th>
                            <th class="text-center text-h6">내용</th>
                        </tr>
                    </thead>
                    <tbody style="color:#333333;">
                        <template v-for="(histItem, histIdx) in adminHistoryList">
                        <tr style="height:68px;" :key="'admin_hist_' + histIdx">
                            <td class="text-center text-h6">{{histItem.reg_dt | dateMin}}</td>
                            <td class="text-center text-h6">{{histItem.action_admin_user_name}}</td>
                            <td class="text-center text-h6">{{histItem.target_admin_user_name}}</td>
                            <td class="text-center text-h6">{{histItem.action_desc}}</td>
                        </tr>
                        </template>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'admin_log_list',
    components: {
    },
    data: () => ({
        adminLogList: [            
            { 
                log_dt: '2020110150123',
                operator_id: 'callgate',  
                operator_name: 'CALLGATE',              
                admin_user_name: '홍길동 대리',
                admin_user_mdn: '01012345679',
                main_admin_yn: 'N',
                log_desc: '일반관리자 추가',
            },
        ],
    }),
    created () { 
    },
    computed: {
        ...mapGetters({
            adminHistoryList: 'admin/getAdminHistoryList',
        }),
    },  
}
</script>